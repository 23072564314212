<!-- 直播发布 -->
<template>
  <div>
    <van-nav-bar
      :title="titleName"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="roomName"
            name="roomName"
            label="直播间名称"
            placeholder="直播间名称"
            autocomplete="off"
            :rules="[{ required: true, message: '请填写直播间名称' }]"
          />
          <van-field
            v-model="result"
            is-link
            readonly
            name="type"
            label="科室类型"
            placeholder="点击选择科室类型"
            @click="showPicker = true"
            :rules="[{ required: true, message: '请选择科室名称' }]"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <!-- 日期 -->
          <van-field name="uploader" label="开始时间">
            <template #input>
              <div style="width: 100%">
                <el-date-picker
                  v-model="startTime"
                  type="datetime"
                  placeholder="选择开始日期时间"
                  :editable="false"
                  size="default"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :disabledDate="disabledDateFn"
                  :disabled-hours="disabledHours"
                  :disabled-minutes="disabledMinutes"
                  :disabled-seconds="disabledSeconds"
                  :default-time="new Date()"
                  style="width: 50vw"
                >
                </el-date-picker>
              </div>
            </template>
          </van-field>
          <van-field name="uploader" label="结束时间">
            <template #input>
              <div style="width: 100%">
                <el-date-picker
                  v-model="endTime"
                  type="datetime"
                  placeholder="选择结束日期时间"
                  :editable="false"
                  size="default"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :disabledDate="disabledDateFn"
                  :disabled-hours="disabledHours"
                  :disabled-minutes="disabledMinutes"
                  :disabled-seconds="disabledSeconds"
                  :default-time="new Date()"
                  style="width: 50vw"
                >
                </el-date-picker>
              </div>
            </template>
          </van-field>
          <van-field name="uploader" label="直播封面上传">
            <template #input>
              <van-uploader
                v-model="cover"
                :max-count="1"
                :max-size="20 * 1024 * 1024"
                @oversize="onOversize"
              />
            </template>
          </van-field>

          <van-field
              name="radio"
              label="是否公开"
              style="width: 96vw"
            >
              <template #input>
                <van-radio-group v-model="checkedOpen" direction="horizontal">
                  <van-radio name="0">公开</van-radio>
                  <van-radio name="1">非公开</van-radio>
                </van-radio-group>
              </template>
            </van-field>
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            v-loading.fullscreen.lock="uploadBool"
            color="rgb(176,217,157)"
          >
            发布
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { showToast } from "vant";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
let route = useRoute();
import { addVideoList, editRoomList } from "@/api/video";
const onClickLeft = () => history.back();

const uploadBool = ref(false);
const cover = ref([]);
const roomName = ref("");
const result = ref("");
const result2 = ref("");
const titleName = ref("");
const showPicker = ref(false);
const checkedOpen = ref("0");
const columns = [
  { text: "胸外科", value: 0 },
  { text: "肝胆外科", value: 1 },
  { text: "神经外科", value: 2 },
  { text: "妇科", value: 3 },
  { text: "泌尿外科", value: 4 },
  { text: "骨科", value: 5 },
  { text: "小儿外科", value: 6 },
];
const onConfirm = ({ selectedOptions }) => {
  result.value = selectedOptions[0]?.text;
  result2.value = selectedOptions[0]?.value;
  showPicker.value = false;
};
const startTime = ref("");
const endTime = ref("");

onMounted(() => {
  if (route.query.id == 1) {
    titleName.value = "直播发布";
  } else {
    titleName.value = "直播编辑";
    const data = JSON.parse(route.query.id);
    roomName.value = data.roomName;
    checkedOpen.value = data.checkedOpen
    result2.value = data.type;
    result.value = columns[parseFloat(data.type)].text;
    startTime.value = data.startTime;
    endTime.value = data.endTime;
    cover.value.push({
      url: data.coverUrl,
    });
  }
});

//提交
const onSubmit = (values: any) => {
  if (route.query.id == 1) {
    if (startTime.value == "" || startTime.value == null) {
      showToast("请选择开始时间");
    } else if (endTime.value == "" || endTime.value == null) {
      showToast("请选择结束时间");
    } else if (cover.value.length == 0) {
      showToast("请上传视频封面");
    } else if (
      startTime.value != "" &&
      startTime.value != null &&
      endTime.value != "" &&
      endTime.value != null
    ) {
      let date1_s = startTime.value.replace(/\-/g, "/");
      let date2_s = endTime.value.replace(/\-/g, "/"); // yyyy-MM-dd 转成 yyyy/MM/dd
      let date1_unix = Date.parse(date1_s); // 转成时间戳
      let date2_unix = Date.parse(date2_s);
      if (date2_unix < date1_unix) {
        showToast("结束时间不能小于开始时间");
      } else {
        uploadBool.value = true;
        let formData = new FormData();
        formData.append("roomName", roomName.value);
        formData.append("type", result2.value);
        formData.append("startTime", startTime.value);
        formData.append("endTime", endTime.value);
        formData.append("file", cover.value[0].file);
        formData.append("isOpen", checkedOpen.value);
        console.log(formData);
        addVideoList(formData)
          .then((res) => {
            console.log(res);
            uploadBool.value = false;
            if (res.data.code == 200) {
              showToast("发布成功");
              history.back();
            }
          })
          .catch((err) => {
            uploadBool.value = false;
          });
      }
    }
  } else {
    if (startTime.value == "" || startTime.value == null) {
      showToast("请选择开始时间");
    } else if (endTime.value == "" || endTime.value == null) {
      showToast("请选择结束时间");
    } else if (cover.value.length == 0) {
      showToast("请上传视频封面");
    } else if (
      startTime.value != "" &&
      startTime.value != null &&
      endTime.value != "" &&
      endTime.value != null
    ) {
      //编辑
      let formData = new FormData();
      const data = JSON.parse(route.query.id);
      formData.append("roomName", roomName.value);
      formData.append("type", result2.value);
      formData.append("startTime", startTime.value);
      formData.append("endTime", endTime.value);
      formData.append("id", data.id);
      formData.append("isOpen", checkedOpen.value);
      if (data.label == null) {
        formData.append("label", "");
      } else {
        formData.append("label", data.label);
      }
      formData.append("file", cover.value[0].file)

      console.log(
        roomName.value,
        result2.value,
        startTime.value,
        cover.value,
        data.roomId
      );
      editRoomList(formData).then((res) => {
        if (res.data.code == 200) {
          showToast("编辑成功");
          history.back();
        }
      });
    }
  }
};

// 保存日期选择框实时选择数据
const selectTimes = ref(Date.now());
const handleDateChange = (value: any) => {
  if (value && value.split(" ") && value.split(" ").length > 0) {
    // 使用空格作为分隔符，将日期时间字符串拆分成数组
    const parts = value.split(" ");
    // 取数组的第一个元素（日期部分）
    const datePart = parts[0];
    // 将日期部分按照连字符 '-' 拆分成数组
    const dateParts = datePart.split("-");
    // 去除月份和日期中的前导零
    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10); // 使用parseInt去除前导零
    const day = parseInt(dateParts[2], 10); // 使用parseInt去除前导零

    // 取数组的第二个元素（时间部分）
    const timePart = parts[1];
    // 将时间部分按照冒号 ':' 拆分成数组
    const timeParts = timePart.split(":");
    // 去除小时、分钟和秒中的前导零
    const hour = parseInt(timeParts[0], 10); // 使用parseInt去除前导零
    const minute = parseInt(timeParts[1], 10); // 使用parseInt去除前导零
    const second = parseInt(timeParts[2], 10); // 使用parseInt去除前导零
    // 实时保存选择时间
    selectTimes.value = new Date(
      `${year}-${month}-${day} ${hour}:${minute}:${second}`
    ).getTime();
  }
};

// 禁用比当前时间小的日期
const disabledDateFn = (time: any) => {
  //比当前时间小的日期禁用（返回false则禁用）
  return time.getTime() < Date.now() - 24 * 3600 * 1000;
};
// 限制比当前时间小的小时
const disabledHours = () => {
  const a: number[] = [];
  if (new Date(selectTimes.value).getTime() > Date.now()) return a;
  for (let i = 0; i < 24; i++) {
    if (new Date().getHours() <= i) continue;
    a.push(i);
  }
  return a;
};
// 禁用比当前时间小的分钟
const disabledMinutes = () => {
  const a: number[] = [];
  if (new Date(selectTimes.value).getTime() > Date.now()) return a;
  for (let i = 0; i < 60; i++) {
    if (new Date().getMinutes() <= i) continue;
    a.push(i);
  }
  return a;
};
// 禁用比当前时间小的秒
const disabledSeconds = () => {
  const a: number[] = [];
  if (new Date(selectTimes.value).getTime() > Date.now()) return a;
  for (let i = 0; i < 60; i++) {
    if (new Date().getSeconds() <= i) continue;
    a.push(i);
  }
  return a;
};
const onOversize = (file: any) => {
  showToast("文件大小不能超过 20 MB");
};
</script>

<style>
/** 移动端展示 **/
@media screen and (max-width: 500px) {
  .el-picker-panel__sidebar {
    width: 96%;
  }
  .el-picker-panel {
    width: 350px !important;
  }
  .el-picker-panel__content {
    width: 100%;
  }
  .el-picker-panel__body {
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    min-width: auto !important;
  }
  .el-picker-panel__sidebar {
    position: relative;
  }
  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.el-date-range-picker__content {
  width: 100% !important;
}

.el-time-panel {
  position: absolute !important;
  left: -95px !important;
}
.van-nav-bar .van-icon {
  color: white !important;
}
</style>
