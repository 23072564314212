<template>
  <div style="width: 100%; height: 100vh; position: relative">
    <div class="title">
      <p>术影云</p>
    </div>
    <div class="btn">
      <van-button
        :loading="loadBool"
        type="success"
        @click="wxlogin"
        v-show="btnBool"
        loading-text="获取授权中"
        >一键登录</van-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { wxLogin } from "@/common/utils.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { loginUser, loginWxUser, getWxUserMsg } from "@/api/user";
import { onMounted, onUnmounted, ref, watch } from "vue";

const btnBool = ref(false);
const loadBool = ref(false);

onMounted(() => {
  // let code0 = route.query.id;
  // console.log("id", code0);
  // if (code0 == 1) {
  //   btnBool.value = true;
  // }
  loadBool.value = false;
  let token = localStorage.getItem("token");
  if (token != null) {
    loginWxUser({}).then((res) => {
      window.location.href = res.data.msg;
    });
  } else {
    btnBool.value = true;
  }
});
const wxlogin = () => {
  loadBool.value = true;
  loginWxUser({})
    .then((res) => {
      router.replace("/MainIndex");
      // console.log(res);
      window.location.href = res.data.msg;
      // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx807d86fb6b3d4fd2&redirect_uri=http%3A%2F%2Fdevelopers.weixin.qq.com&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
    })
    .catch((err) => {
      loadBool.value = false;
    });
};
// const wxlogin = () => {
//   loadBool.value = true;
//   // loginWxUser({})
//   //   .then((res) => {
//       router.replace("/MainIndex");
//       // window.location.href = res.data.msg;
//       window.location.href = 'https://www.cohen.net.cn/ai-chat/login';

//     // })
//     // .catch((err) => {
//     //   loadBool.value = false;
//     // });
// };
</script>

<style scoped>
.title {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.title > p {
  font-size: 20px;
  text-align: center;
}
.btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.btn > p {
  font-size: 14px;
  color: #9a9fa0;
  text-align: center;
}
</style>

